document.addEventListener('DOMContentLoaded', function () {
  const burgerButton = document.querySelector('.burger');
  const navMenu = document.querySelector('.header__nav');
  const logo = document.querySelector('.header__logo-link');

  if (burgerButton && navMenu && logo) {
    function toggleMenu() {
      burgerButton.classList.toggle('active');
      navMenu.classList.toggle('show');
      document.body.style.overflow = navMenu.classList.contains('show') ? 'hidden' : 'auto';
    }

    burgerButton.addEventListener('click', toggleMenu);

    // Скрытие логотипа при прокрутке ниже 100px
    window.addEventListener('scroll', function () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 50) {
        logo.classList.add('hidden');
      } else {
        logo.classList.remove('hidden');
      }
    });
  } else {
    console.error('Не все элементы меню найдены. Проверьте HTML-код.');
  }
});

document.addEventListener('DOMContentLoaded', function() {
  var whatsappButton = document.getElementById('whatsappButton');
  if (whatsappButton) {
    whatsappButton.onclick = function() {
      var phoneNumber = '+79220593777'; // Замените на ваш номер телефона
      var message = encodeURIComponent('Здравствуйте, хочу заказать проект дома');
      var whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(whatsappUrl, '_blank'); // Открывает WhatsApp в новой вкладке
    };
  }
});


// Services


document.querySelectorAll('.services__item').forEach(item => {
  item.addEventListener('mouseover', event => {
      const servicesSection = document.querySelector('.services');
      setTimeout(() => {
          if (item.classList.contains('services__item--architecture')) {
              servicesSection.style.backgroundImage = "url('../img/services_archi.webp')";
          } else if (item.classList.contains('services__item--interior')) {
              servicesSection.style.backgroundImage = "url('../img/services_interior.webp')";
          } else if (item.classList.contains('services__item--bim')) {
              servicesSection.style.backgroundImage = "url('../img/services_bim.webp')";
          } else if (item.classList.contains('services__item--landscape')) {
              servicesSection.style.backgroundImage = "url('../img/services_landscape.webp')";
          }
      }, 400); // Задержка в 300 миллисекунд
  });
});





// FAQ


// Дождитесь полной загрузки DOM.
document.addEventListener('DOMContentLoaded', function() {
    // Находим все кнопки внутри элементов FAQ и добавляем к ним обработчик события 'click'.
    document.querySelectorAll('.faq__item-button').forEach(function(button) {
      button.addEventListener('click', function() {
        // Находим ближайший родительский элемент '.faq__item' и в нем ищем '.faq__item-info'.
        var info = this.closest('.faq__item').querySelector('.faq__item-info');
  
        // Переключаем класс 'ac-active' для отображения или скрытия информации.
        info.classList.toggle('ac-active');
  
        // Переключаем класс 'faq__item-btn-rotate' для анимации иконки стрелки.
        var svg = this.querySelector('.faq__item-btn');
        svg.classList.toggle('faq__item-btn-rotate');
      });
    });
  });
  



// // Подключаем GSAP и ScrollTrigger
 gsap.registerPlugin(ScrollTrigger);


  // Медленное приближение для '.hero__img'
  gsap.from(".hero", {
    scrollTrigger: {
      trigger: ".hero",
      start: "top top",
      end: "bottom top",
      scrub: 1, // Плавное изменение в зависимости от прокрутки
    },
    filter: 'brightness(1) blur(0px) grayscale(0)',
  });

  
  
  gsap.to(".info-point", {
    scrollTrigger: {
      trigger: ".hero",
      start: "top top",
      end: "bottom top",
      scrub: true, // Плавное изменение в зависимости от прокрутки
    },
      y: -1000,
  
  });





// Создаем анимацию для элемента с классом .advantage

// Создание таймлайна с ScrollTrigger для секции .advantage
const tlAdvantage = gsap.timeline({
  scrollTrigger: {
    trigger: ".advantage",
    start: "-450 0",
    end: "bottom top",
    // Задайте конец анимации в зависимости от вашего дизайна
    scrub: true
  }
});



// Добавление анимаций для элементов внутри таймлайна
tlAdvantage.from('.advantage', {
  backgroundColor: "#060608",
  duration: 3
})

gsap.from(".advantage__title", {
  duration: 8.5, // Установите желаемую продолжительность анимации
  opacity: 0, // Начальная прозрачность, // Начальное смещение по оси Y для добавления эффекта "подъема"
  ease: "power3.out", // Плавное замедление анимации к концу
  scrollTrigger: {
    trigger: ".advantage__list",
    start: "top 80%", // Начало анимации при входе списка в зону видимости на 80%
    end: "bottom top",
    toggleActions: "play none none none" // Поведение анимации при скроллинге
  }
});



gsap.from(".advantage__item", {
  duration: 1.5, // продолжительность анимации каждого элемента
  opacity: 0, // начальная прозрачность
  y: 50, // начальное смещение по оси Y
  stagger: 0.7, // задержка анимации между элементами
  ease: "back.out(1.7)", // тип анимации для более "мягкого" появления
  scrollTrigger: {
    trigger: ".advantage__list",
    start: "top 80%", // начало анимации, когда список входит в область видимости на 80%
    end: "bottom top",
    toggleActions: "play none none none" // поведение при скролле
  }
});







  const beforeImg = document.querySelector('.before img');
  const afterImg = document.querySelector('.after img');
  
  // Установим начальную прозрачность для "после" изображения, чтобы оно было полностью невидимым
  gsap.set(afterImg, {opacity: 1});
  




  // Анимация изменения прозрачности изображений
  gsap.to(afterImg, {
    opacity: 0,
    scrollTrigger: {
      trigger: ".compare__container",
      start: "-400 0",
      end: "300 0",
      scrub: true,
    }
  });

    // Установим начальную прозрачность для "после" изображения, чтобы оно было полностью невидимым
    gsap.set(beforeImg, {opacity: 0});
  
    // Анимация изменения прозрачности изображений
    gsap.to(beforeImg, {
      opacity: 1,
      scrollTrigger: {
        trigger: ".compare__container",
        start: "top center",
        end: "bottom center",
        scrub: true,
      }
    });



    gsap.to(".render img", {
      scrollTrigger: {
        trigger: ".render__container",
        start: "top center",
        end: "bottom center",
        scrub: true, // Плавное изменение в зависимости от прокрутки
      },
      

    });
    
    
   // Анимация для секции "Мы в СМИ"
gsap.timeline({
  scrollTrigger: {
    trigger: ".smi", // Активируем анимацию при прокрутке до секции ".smi"
    start: "top 50%", // Анимация начнется, когда верх секции достигнет 80% высоты экрана
    end: "bottom top", // Закончится, когда секция уйдет из зоны видимости
    toggleActions: "play none none none" // Запускаем анимацию только один раз при прокрутке
  }
})
.from(".smi__title", {
  y: -50,       // Заголовок появляется сверху
  opacity: 0,   // Исходная прозрачность
  scale: 0.9,   // Небольшое уменьшение
  duration: 1   // Длительность анимации заголовка
})
.from(".smi__item", {
  opacity: 0,   // Исходная прозрачность элементов списка
  scale: 0.9,   // Небольшое уменьшение элементов списка
  y: 30,        // Появление снизу
  stagger: 0.3, // Задержка между каждым элементом списка
  duration: 1   // Длительность анимации каждого элемента
}, "-=0.5");    // Начинаем анимацию списка раньше окончания анимации заголовка



// Обработчик клика для кнопки
document.getElementById("download-brief").addEventListener("click", function() {
  // Создаем элемент <a>
  const link = document.createElement("a");
  
  // Указываем путь к файлу
  link.href = "https://architector.tech/brief.docx"; // Замените на реальный путь к вашему файлу
  
  // Задаем имя файла при скачивании
  link.download = "brief.docx";
  
  // Добавляем элемент на страницу
  document.body.appendChild(link);
  
  // Программно кликаем по ссылке
  link.click();
  
  // Удаляем элемент после скачивания
  document.body.removeChild(link);
});



function smoothScrollTo(target, duration) {
  const start = window.pageYOffset;
  const distance = target - start;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, start, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}


document.addEventListener('DOMContentLoaded', function() {
  // Плавная прокрутка к секции при клике на навигационные элементы
  const scrollLinks = document.querySelectorAll('a[href^="#stage"]');
  scrollLinks.forEach(link => {
      link.addEventListener('click', function(e) {
          e.preventDefault();
          const targetId = this.getAttribute('href');
          const targetElement = document.querySelector(targetId);
          
          if (targetElement) {
              window.scrollTo({
                  top: targetElement.offsetTop - 100,
                  behavior: 'smooth'
              });
          }
      });
  });
  
  // Скачивание брифа
  const downloadBriefBtn = document.getElementById('download-brief');
  if (downloadBriefBtn) {
      downloadBriefBtn.addEventListener('click', function() {
          // Здесь можно добавить логику для скачивания файла или открытия формы
          alert('Скачивание брифа начнется через несколько секунд...');
          // Пример редиректа на файл
          // window.location.href = '/path/to/brief.pdf';
      });
  }
});

  
document.addEventListener('DOMContentLoaded', () => {
  const isMobile = window.innerWidth <= 768;
  const slides = document.querySelectorAll('.slide');
  const sliderControls = document.querySelector('.slider-controls');
  
  if (isMobile) {
      // Отключаем функционал слайдера для мобильной версии

      // Скрываем все слайды, кроме пятого (индекс 4)
      slides.forEach((slide, index) => {
          if (index === 0) {
              slide.style.display = 'block';
              slide.classList.add('active');
          } else {
              slide.style.display = 'none';
              slide.classList.remove('active');
          }
      });

      // Скрываем элементы управления слайдером
      if (sliderControls) {
          sliderControls.style.display = 'none';
      }

  } else {
      // Для десктопной версии запускаем стандартную логику слайдера

      let currentSlide = 0;
      let autoPlay = true;
      let interval = null;
      const dotsContainer = document.querySelector('.slider-dots');

      // Создаем точки навигации
      slides.forEach((_, index) => {
          const dot = document.createElement('div');
          dot.classList.add('slider-dot');
          if (index === 0) dot.classList.add('active');
          dot.addEventListener('click', () => goToSlide(index));
          dotsContainer.appendChild(dot);
      });

      // Обработчики для стрелок
      document.querySelector('.prev-slide').addEventListener('click', () => {
          goToSlide(currentSlide - 1);
          resetAutoplay();
      });

      document.querySelector('.next-slide').addEventListener('click', () => {
          goToSlide(currentSlide + 1);
          resetAutoplay();
      });

      // Функция перехода на нужный слайд
      function goToSlide(n) {
          currentSlide = (n + slides.length) % slides.length;

          slides.forEach((slide, index) => {
              slide.classList.toggle('active', index === currentSlide);
          });

          document.querySelectorAll('.slider-dot').forEach((dot, index) => {
              dot.classList.toggle('active', index === currentSlide);
          });
      }

      // Сброс автоплей
      function resetAutoplay() {
          if (autoPlay) {
              clearInterval(interval);
              interval = setInterval(() => goToSlide(currentSlide + 1), 21000);
          }
      }

      // Запуск автоплей
      interval = setInterval(() => goToSlide(currentSlide + 1), 16000);

      // Обработчики свайпов для мобильных (если необходимо, можно отключить их для мобильных)
      let touchStartX = 0;
      document.addEventListener('touchstart', e => {
          touchStartX = e.touches[0].clientX;
      });

      document.addEventListener('touchend', e => {
          const touchEndX = e.changedTouches[0].clientX;
          const diff = touchStartX - touchEndX;
          if (Math.abs(diff) > 50) {
              if (diff > 0) goToSlide(currentSlide + 1);
              else goToSlide(currentSlide - 1);
              resetAutoplay();
          }
      });
  }
});



// Простой скрипт для анимации и интерактивности в мобильной версии
document.addEventListener('DOMContentLoaded', function() {
  const contactToggle = document.getElementById('contact-toggle');
  const contactOptions = document.getElementById('contact-options');
  
  contactToggle.addEventListener('click', function(event) {
      event.stopPropagation();
      contactToggle.classList.toggle('active');
      contactOptions.classList.toggle('active');
  });
  
  // Закрывать меню при клике вне его
  document.addEventListener('click', function(event) {
      if (!contactToggle.contains(event.target) && !contactOptions.contains(event.target)) {
          contactToggle.classList.remove('active');
          contactOptions.classList.remove('active');
      }
  });
});

  
  // Прокрутка миниатюр (имитация карусели)
  const thumbnails = document.querySelector('.minimal-hero__thumbnails');
  const projectDots = document.querySelectorAll('.mobile-project-dot');
  
  if (thumbnails && projectDots.length) {
      let currentDot = 0;
      
      // Функция для обновления активной точки
      function updateActiveDot() {
          projectDots.forEach(dot => dot.classList.remove('active'));
          projectDots[currentDot].classList.add('active');
      }
      
      // Обработчик скролла миниатюр
      thumbnails.addEventListener('scroll', function() {
          const scrollPosition = this.scrollLeft;
          const maxScroll = this.scrollWidth - this.clientWidth;
          const scrollRatio = scrollPosition / maxScroll;
          
          // Определяем активную точку на основе скролла
          currentDot = Math.min(
              Math.floor(scrollRatio * projectDots.length),
              projectDots.length - 1
          );
          
          updateActiveDot();
      });
      
      // Клик по точкам для прокрутки миниатюр
      projectDots.forEach((dot, index) => {
          dot.addEventListener('click', function() {
              currentDot = index;
              updateActiveDot();
              
              const scrollWidth = thumbnails.scrollWidth - thumbnails.clientWidth;
              const targetScroll = (index / (projectDots.length - 1)) * scrollWidth;
              
              thumbnails.scrollTo({
                  left: targetScroll,
                  behavior: 'smooth'
              });
          });
      });
  }





document.addEventListener('DOMContentLoaded', function() {
  // Фильтрация проектов
  const filterButtons = document.querySelectorAll('.filter-button');
  const projectCards = document.querySelectorAll('.project-card');
  
  filterButtons.forEach(button => {
      button.addEventListener('click', function() {
          // Удаляем активный класс со всех кнопок
          filterButtons.forEach(btn => btn.classList.remove('active'));
          // Добавляем активный класс на нажатую кнопку
          this.classList.add('active');
          
          const filter = this.dataset.filter;
          
          // Фильтруем проекты
          projectCards.forEach(card => {
              if (filter === 'all' || card.dataset.category === filter) {
                  card.style.display = 'block';
                  setTimeout(() => {
                      card.style.opacity = '1';
                      card.style.transform = 'translateY(0)';
                  }, 50);
              } else {
                  card.style.opacity = '0';
                  card.style.transform = 'translateY(20px)';
                  setTimeout(() => {
                      card.style.display = 'none';
                  }, 300);
              }
          });
      });
  });
  
  // Открытие модального окна
  projectCards.forEach(card => {
      card.addEventListener('click', function() {
          const modalId = 'modal-' + this.dataset.id;
          const modal = document.getElementById(modalId);
          
          if (modal) {
              modal.style.display = 'block';
              document.body.style.overflow = 'hidden'; // Блокируем прокрутку страницы
          }
      });
  });
  
  // Закрытие модального окна
  const closeButtons = document.querySelectorAll('.modal-close');
  closeButtons.forEach(button => {
      button.addEventListener('click', function() {
          const modal = this.closest('.project-modal');
          modal.style.display = 'none';
          document.body.style.overflow = 'auto'; // Возвращаем прокрутку страницы
      });
  });
  
  // Закрытие модального окна при клике вне его контента
  document.querySelectorAll('.project-modal').forEach(modal => {
      modal.addEventListener('click', function(event) {
          if (event.target === this) {
              this.style.display = 'none';
              document.body.style.overflow = 'auto';
          }
      });
  });
  
  // Смена изображения в модальном окне
  document.querySelectorAll('.thumbnail').forEach(thumb => {
      thumb.addEventListener('click', function() {
          const mainImage = this.closest('.modal-gallery').querySelector('.main-image');
          const thumbnails = this.closest('.image-thumbnails').querySelectorAll('.thumbnail');
          
          // Обновляем активный класс
          thumbnails.forEach(t => t.classList.remove('active'));
          this.classList.add('active');
          
          // Меняем основное изображение с анимацией
          mainImage.style.opacity = '0';
          setTimeout(() => {
              mainImage.src = this.src;
              mainImage.style.opacity = '1';
          }, 300);
      });
  });
});








