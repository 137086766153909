document.addEventListener('DOMContentLoaded', function () {
  const burgerButton = document.querySelector('.burger');
  const navMenu = document.querySelector('.header__nav');
  const logo = document.querySelector('.header__logo-link');

  if (burgerButton && navMenu && logo) {
    function toggleMenu() {
      burgerButton.classList.toggle('active');
      navMenu.classList.toggle('show');
      document.body.style.overflow = navMenu.classList.contains('show') ? 'hidden' : 'auto';
    }

    burgerButton.addEventListener('click', toggleMenu);

    // Скрытие логотипа при прокрутке ниже 100px
    window.addEventListener('scroll', function () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 50) {
        logo.classList.add('hidden');
      } else {
        logo.classList.remove('hidden');
      }
    });
  } else {
    console.error('Не все элементы меню найдены. Проверьте HTML-код.');
  }
});

document.addEventListener('DOMContentLoaded', function() {
  var whatsappButton = document.getElementById('whatsappButton');
  if (whatsappButton) {
    whatsappButton.onclick = function() {
      var phoneNumber = '+79220593777'; // Замените на ваш номер телефона
      var message = encodeURIComponent('Здравствуйте, хочу заказать проект дома');
      var whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
      window.open(whatsappUrl, '_blank'); // Открывает WhatsApp в новой вкладке
    };
  }
});


// Services


document.querySelectorAll('.services__item').forEach(item => {
  item.addEventListener('mouseover', event => {
      const servicesSection = document.querySelector('.services');
      setTimeout(() => {
          if (item.classList.contains('services__item--architecture')) {
              servicesSection.style.backgroundImage = "url('../img/services_archi.webp')";
          } else if (item.classList.contains('services__item--interior')) {
              servicesSection.style.backgroundImage = "url('../img/services_interior.webp')";
          } else if (item.classList.contains('services__item--bim')) {
              servicesSection.style.backgroundImage = "url('../img/services_bim.webp')";
          } else if (item.classList.contains('services__item--landscape')) {
              servicesSection.style.backgroundImage = "url('../img/services_landscape.webp')";
          }
      }, 400); // Задержка в 300 миллисекунд
  });
});





// FAQ


// Дождитесь полной загрузки DOM.
document.addEventListener('DOMContentLoaded', function() {
    // Находим все кнопки внутри элементов FAQ и добавляем к ним обработчик события 'click'.
    document.querySelectorAll('.faq__item-button').forEach(function(button) {
      button.addEventListener('click', function() {
        // Находим ближайший родительский элемент '.faq__item' и в нем ищем '.faq__item-info'.
        var info = this.closest('.faq__item').querySelector('.faq__item-info');
  
        // Переключаем класс 'ac-active' для отображения или скрытия информации.
        info.classList.toggle('ac-active');
  
        // Переключаем класс 'faq__item-btn-rotate' для анимации иконки стрелки.
        var svg = this.querySelector('.faq__item-btn');
        svg.classList.toggle('faq__item-btn-rotate');
      });
    });
  });
  



// // Подключаем GSAP и ScrollTrigger
 gsap.registerPlugin(ScrollTrigger);

// Проверяем, соответствует ли устройство десктопной версии
if (window.matchMedia("(min-width: 1024px)").matches) {



  // Медленное приближение для '.hero__img'
  gsap.from(".hero__img", {
    scrollTrigger: {
      trigger: ".hero",
      start: "top top",
      end: "bottom top",
      scrub: 1, // Плавное изменение в зависимости от прокрутки
    },
    scale: 1.1, // Начальный масштаб
    filter: 'brightness(1) blur(0px) grayscale(0)',
  });
  
  
  
  
  
  
  gsap.to(".hero__block", {
    scrollTrigger: {
      trigger: ".hero",
      start: "top top",
      end: "bottom top",
      scrub: true, // Плавное изменение в зависимости от прокрутки
    },
      yPercent: 10,
      stagger: 0.2,
  });
  
  
  gsap.to(".hero__discr", {
    scrollTrigger: {
      trigger: ".hero",
      start: "top top",
      end: "bottom top",
      scrub: true, // Плавное изменение в зависимости от прокрутки
    },
      yPercent: 30,
  
  });
  
  gsap.to(".info-point", {
    scrollTrigger: {
      trigger: ".hero",
      start: "top top",
      end: "bottom top",
      scrub: true, // Плавное изменение в зависимости от прокрутки
    },
      y: -1000,
  
  });
}




// Создаем анимацию для элемента с классом .advantage

// Создание таймлайна с ScrollTrigger для секции .advantage
const tlAdvantage = gsap.timeline({
  scrollTrigger: {
    trigger: ".advantage",
    start: "-450 0",
    end: "bottom top",
    // Задайте конец анимации в зависимости от вашего дизайна
    scrub: true
  }
});



// Добавление анимаций для элементов внутри таймлайна
tlAdvantage.from('.advantage', {
  backgroundColor: "#060608",
  duration: 3
})

gsap.from(".advantage__title", {
  duration: 8.5, // Установите желаемую продолжительность анимации
  opacity: 0, // Начальная прозрачность, // Начальное смещение по оси Y для добавления эффекта "подъема"
  ease: "power3.out", // Плавное замедление анимации к концу
  scrollTrigger: {
    trigger: ".advantage__list",
    start: "top 80%", // Начало анимации при входе списка в зону видимости на 80%
    end: "bottom top",
    toggleActions: "play none none none" // Поведение анимации при скроллинге
  }
});



gsap.from(".advantage__item", {
  duration: 1.5, // продолжительность анимации каждого элемента
  opacity: 0, // начальная прозрачность
  y: 50, // начальное смещение по оси Y
  stagger: 0.7, // задержка анимации между элементами
  ease: "back.out(1.7)", // тип анимации для более "мягкого" появления
  scrollTrigger: {
    trigger: ".advantage__list",
    start: "top 80%", // начало анимации, когда список входит в область видимости на 80%
    end: "bottom top",
    toggleActions: "play none none none" // поведение при скролле
  }
});





// Создаем анимацию для элемента с классом .advantage



  const beforeImg = document.querySelector('.before img');
  const afterImg = document.querySelector('.after img');
  
  // Установим начальную прозрачность для "после" изображения, чтобы оно было полностью невидимым
  gsap.set(afterImg, {opacity: 1});
  




  // Анимация изменения прозрачности изображений
  gsap.to(afterImg, {
    opacity: 0,
    scrollTrigger: {
      trigger: ".compare__container",
      start: "-400 0",
      end: "300 0",
      scrub: true,
    }
  });

    // Установим начальную прозрачность для "после" изображения, чтобы оно было полностью невидимым
    gsap.set(beforeImg, {opacity: 0});
  
    // Анимация изменения прозрачности изображений
    gsap.to(beforeImg, {
      opacity: 1,
      scrollTrigger: {
        trigger: ".compare__container",
        start: "top center",
        end: "bottom center",
        scrub: true,
      }
    });



    gsap.to(".render img", {
      scrollTrigger: {
        trigger: ".render__container",
        start: "top center",
        end: "bottom center",
        scrub: true, // Плавное изменение в зависимости от прокрутки
      },
      

    });
    
    
   // Анимация для секции "Мы в СМИ"
gsap.timeline({
  scrollTrigger: {
    trigger: ".smi", // Активируем анимацию при прокрутке до секции ".smi"
    start: "top 10%", // Анимация начнется, когда верх секции достигнет 80% высоты экрана
    end: "bottom top", // Закончится, когда секция уйдет из зоны видимости
    toggleActions: "play none none none" // Запускаем анимацию только один раз при прокрутке
  }
})
.from(".smi__title", {
  y: -50,       // Заголовок появляется сверху
  opacity: 0,   // Исходная прозрачность
  scale: 0.9,   // Небольшое уменьшение
  duration: 1   // Длительность анимации заголовка
})
.from(".smi__item", {
  opacity: 0,   // Исходная прозрачность элементов списка
  scale: 0.9,   // Небольшое уменьшение элементов списка
  y: 30,        // Появление снизу
  stagger: 0.3, // Задержка между каждым элементом списка
  duration: 1   // Длительность анимации каждого элемента
}, "-=0.5");    // Начинаем анимацию списка раньше окончания анимации заголовка



// Обработчик клика для кнопки
document.getElementById("download-brief").addEventListener("click", function() {
  // Создаем элемент <a>
  const link = document.createElement("a");
  
  // Указываем путь к файлу
  link.href = "https://architector.tech/brief.docx"; // Замените на реальный путь к вашему файлу
  
  // Задаем имя файла при скачивании
  link.download = "brief.docx";
  
  // Добавляем элемент на страницу
  document.body.appendChild(link);
  
  // Программно кликаем по ссылке
  link.click();
  
  // Удаляем элемент после скачивания
  document.body.removeChild(link);
});



function smoothScrollTo(target, duration) {
  const start = window.pageYOffset;
  const distance = target - start;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, start, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}
